"use client";
import { useCallback, useEffect, useState } from "react";
import { IconButton, Stack, Fade, Box } from "@mui/material";
import {
  ChevronLeft as ScrollLeftIcon,
  ChevronRight as ScrollRightIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../service/mobx";
import { filterChipScrollSx } from "../../view/search/Filters";

function HorizontalChips({ chips }) {
  const [ref, setRef] = useState();
  const [hide, setHide] = useState({ left: true, right: false });
  const { device } = useStore();
  const isPhone = device.measured === false || device.isPhone;

  const hideScrollButtons = useCallback(() => {
    const { scrollLeft, scrollWidth, clientWidth } = ref;
    const maxScrollLeft = scrollWidth - clientWidth;

    setHide({
      left: scrollLeft <= 0,
      right: maxScrollLeft <= Math.ceil(scrollLeft)
    });
  }, [ref]);

  useEffect(() => {
    if (ref) {
      hideScrollButtons();
    }
  }, [ref, hideScrollButtons, chips]);

  return (
    <Stack
      useFlexGap
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Fade
        mountOnEnter={isPhone}
        unmountOnExit={isPhone}
        in={isPhone === false && hide.left === false}
      >
        <Box
          sx={theme => ({
            pr: 1,
            left: 0,
            zIndex: 10,
            top: "50%",
            position: "absolute",
            transform: "translateY(-50%)",
            backgroundColor: "surface.color",
            ...theme.fadeEdge("right")
          })}
        >
          <IconButton
            onClick={() => {
              ref.scrollBy({
                behavior: "smooth",
                left: -Math.ceil(ref.offsetWidth + 10)
              });
            }}
          >
            <ScrollLeftIcon sx={{ color: "secondary.color" }} />
          </IconButton>
        </Box>
      </Fade>
      <Stack
        ref={setRef}
        useFlexGap
        spacing={0.5}
        direction="row"
        px={{ compact: 2, expanded: 0 }}
        mx={{ compact: "-16px !important", expanded: "0 !important" }}
        sx={{
          zIndex: 0,
          overflowX: "auto",
          whiteSpace: "nowrap",
          ...filterChipScrollSx
        }}
        onScroll={hideScrollButtons}
        onClick={event => {
          const chip = event.target;
          const chipRect = chip.getBoundingClientRect();
          const containerRect = ref.getBoundingClientRect();

          // Get the chip and container center positions
          const chipCenter = (chipRect.left + chipRect.right) / 2;
          const containerCenter =
            (containerRect.left + containerRect.right) / 2;

          // Calculate how much to scroll to center the chip
          const scrollAmount = chipCenter - containerCenter;

          // Scroll the container by the calculated amount
          ref.scrollBy({
            behavior: "smooth",
            left: scrollAmount
          });
        }}
      >
        {chips}
      </Stack>
      <Fade
        mountOnEnter={isPhone}
        unmountOnExit={isPhone}
        in={isPhone === false && hide.right === false}
      >
        <Box
          sx={theme => ({
            pl: 1,
            right: 0,
            zIndex: 10,
            top: "50%",
            position: "absolute",
            transform: "translateY(-50%)",
            backgroundColor: "surface.color",
            ...theme.fadeEdge("left")
          })}
        >
          <IconButton
            onClick={() => {
              ref.scrollBy({ behavior: "smooth", left: ref.offsetWidth });
            }}
          >
            <ScrollRightIcon sx={{ color: "secondary.color" }} />
          </IconButton>
        </Box>
      </Fade>
    </Stack>
  );
}

export default observer(HorizontalChips);
